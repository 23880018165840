<template>
  <div v-if="isRefreshing">{{ globalRefreshMessage }}</div>
  <div v-else>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat dense>
          <v-toolbar-title>{{ objectName }}</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn icon :to="`/form/settings/${activeObject}`">
            <v-icon color="red">mdi-amplifier</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- end of toolbar 1 -->

        <!-- start of toolbar 2 -->
        <v-toolbar color="secondary" flat dense>
          <v-btn
            class="mr-1"
            outlined
            v-if="docRights && docRights.write && withPayment == 0"
            color="toolbarIcon"
            text
            :to="`/sales/doc/create/${activeObject}`"
          >
            <v-icon left dark>mdi-plus</v-icon>New
          </v-btn>
          <v-btn
            class="mr-1"
            outlined
            v-if="docRights && docRights.write && withPayment == 1"
            color="toolbarIcon"
            text
            :to="`/sales/doc/create/${activeObject}?withPayment=1`"
          >
            <v-icon left dark>mdi-plus</v-icon>New
          </v-btn>
          <v-spacer></v-spacer>

          <v-col cols="2">
            <v-btn icon class="ml-10" right @click="setFilter">
              <v-icon>mdi-sync</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="DocFilter"
              :items="docTypes"
              item-text="name"
              item-value="value"
              label="Filter Documents"
              @change="setFilter"
            ></v-autocomplete>
          </v-col>
        </v-toolbar>
        <!-- end of toolbar 2 -->
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-col cols="3">
                <v-autocomplete
                  dense
                  outlined
                  v-model="created_by"
                  :items="allUsersData"
                  label="Created By"
                  item-text="name"
                  @change="setFilter"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-search"
                label="Search"
                single-line
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                dense
                v-model="selectedDocs"
                v-if="docRights.read == true"
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="docData"
                :items-per-page="options.itemsPerPage"
                :search="search"
                :options.sync="options"
                :server-items-length="totalItems"
                class="elevation-1"
                :server-search="true"
                :footer-props="{
                  itemsPerPageOptions: [10, 50, 100, 150, 200],
                  showCurrentPage: true,
                }"
              >
                <!-- start of posting date template -->
                <!--              <template v-slot:item.DocDate="{ item }">{{ item.DocDate | moment("Do MMM YYYY")}}</template>-->
                <template v-slot:item.created_at="{ item }">{{
                  item.created_at | moment("LLLL")
                }}</template>
                <!-- end of posting date template -->

                <!-- action template -->
                <template v-slot:item.DocNum="{ item }">
                  <v-btn
                    class="btnLink"
                    color="flatButton"
                    text
                    :to="`/sales/doc/${activeObject}/${item.id}?isDoc=${item.isDoc}&ExtRef=${item.ExtRef}`"
                    >{{ item.DocNum }}</v-btn
                  >
                </template>

                <template v-slot:item.syncStatus="{ item }">
                  <v-btn
                    @click="openerrorDocDialog(item)"
                    color="red"
                    icon
                    v-show="item.withErrors == true"
                  >
                    <v-icon>mdi-alert</v-icon>
                  </v-btn>
                  <v-btn color="green" icon v-show="item.withErrors == false">
                    <v-icon>mdi-check</v-icon>
                  </v-btn>

                  <v-btn color="grey" icon v-show="item.withErrors == 'upload'">
                    <v-icon>mdi-upload</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-autocomplete
                    single-line
                    dense
                    outlined
                    v-model="item.actionType"
                    :items="
                      item.DocStatus == 'O'
                        ? availableActionsOpen
                        : availableActionsClosed
                    "
                    item-text="ActionName"
                    item-value="value"
                    @change="processAction(item)"
                    style="width: 100px"
                  ></v-autocomplete>
                </template>
              </v-data-table>

              <v-alert
                type="
                    error"
                v-if="docRights.read == false"
                >You are not authorised to read data</v-alert
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- snackbar -->

      <!-- category quick add dialog -->
      <v-dialog v-model="closeDocDialog" max-width="650px">
        <v-card>
          <v-toolbar dense color="red" dark>
            <v-toolbar-title>Close Document</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="closeDocDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12"> Closing cannot be reversed,Continue?. </v-col>

                <v-row>
                  <v-col cols="6">
                    <v-btn color="green" dark @click="closeDocument()">Yes</v-btn>
                  </v-col>

                  <v-col cols="6">
                    <v-btn color="red" dark @click="closeDocDialog = false"
                      >No</v-btn
                    >
                  </v-col>
                </v-row>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- end of modal-->

      <!-- category quick add dialog -->
      <v-dialog v-model="errorDocDialog" max-width="650px">
        <v-card>
          <v-toolbar dense color="red" dark>
            <v-toolbar-title>Sync Process Error Message</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="errorDocDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  This document was not sync to SAP due to below following error:
                </v-col>
                <v-col cols="12">
                  <span>
                    {{ this.ErrorMessage }}
                  </span>
                </v-col>

                <v-row>
                  <v-col cols="6">
                    <v-btn color="green" dark @click="errorDocDialog = false"
                      >Close</v-btn
                    >
                  </v-col>
                </v-row>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- end of modal-->

      <!-- Approvers Dialog -->
      <v-dialog
        v-model="approversDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="accent">
            <v-toolbar-title>
              <v-btn icon dark @click="approversDialog = false">
                <v-icon dark>mdi-keyboard-backspace</v-icon> </v-btn
              >Approver Status
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :items-per-page="itemsPerPage"
                    :headers="approverHeaders"
                    :items="approversDetails"
                    item-key="id"
                  >
                    <template v-slot:item.ApprovalTime="{ item }">{{
                      item.ApprovalTime | moment("LLLL")
                    }}</template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- end of modal-->

      <snackbar ref="snackbar"></snackbar>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";
import refreshMixin from "@/mixins/refreshMixin";

export default {
  mixins:[
      refreshMixin
  ],
  data: () => ({
    search: "",
    objectName: null,
    objectCode: null,
    createUrl: null,
    withPayment: null,
    itemsPerPage: 100,
    docData: [],
    selectedDocs: [],
    activeObject: null,
    singleSelect: true,
    loading: false,
    docRights: {},
    dateItem: {},
    approversDetails: [],
    actionType: null,
    closeDocDialog: false,
    errorDocDialog: false,
    approversDialog: false,
    ErrorMessage: null,
    DocFilter: 1,
    // availableActionsClosed: [{ ActionName: "View", value: "View" }],
    // availableActionsOpen: [
    //   { ActionName: "View", value: "View" },
    //   { ActionName: "Close", value: "Close" },
    // ],
    options: {
      rowsPerPageOptions: [10, 20, 30],
      itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 50,
      page: 1,
    },
    totalItems: 0,
    docTypes: [
      { name: "Documents", value: 1 },
      { name: "Drafts", value: 0 },
      { name: "SAP Open Documents", value: 2 },
    ],
    approverHeaders: [
      { text: "Name", value: "userDetails.name" },
      { text: "Status", value: "StatusComment" },
      { text: "Time", value: "ApprovalTime" },
      { text: "Remarks", value: "StatusRemarks" },
    ],
    created_by: null,
  }),
  watch: {
    "$route.params.objectId": {
      handler: "getDocs",
      immediate: true,
    },
    // options: {
    //   handler() {
    //     console.log("fetch docs");
    //     this.getDocs(this.activeObject);
    //   },
    //   deep: true,
    // },
    search: {
      handler: "setFilter",
      immediate: false,
    },
  },
  mounted: function () {},
  computed: {
    ... mapGetters({
      getDocumentModel: "getDocumentModel",
      getDocumentFormSettings: "documentFormSetting/getDocumentFormSettings",
      getDocumentRights: "getDocumentRights",
      localSettings:"getLocalSettings",
    }),
    allUsersData() {
      return this.$store.state.userdata;
    },
    headers() {
      if (this.activeObject == 205) {
        return [
          { text: "", value: "syncStatus" },
          { text: "Doc No.", value: "DocNum" },
          { text: "SAP #", value: "ExtRefDocNum" },
          { text: "Requester Name", value: "ReqName" },
          { text: "Posting Date", value: "created_at" },
          { text: "Dct Total", value: "DocTotal" },
          { text: "Created By", value: "ohem.full_name" },
          { text: "Action", value: "action" },
        ];
      }
      return [
        { text: "", value: "syncStatus" },
        { text: "Doc No.", value: "DocNum" },
        { text: "SAP #", value: "ExtRefDocNum" },
        { text: "Customer Name", value: "CardName" },
        { text: "Date", value: "created_at" },
        { text: "Total", value: "DocTotal" },
        { text: "Created By", value: "ohem.full_name" },
        { text: "Action", value: "action" },
      ];
    },
    availableActionsClosed() {
      return [{ ActionName: "View", value: "View" }];
    },

    availableActionsOpen() {
      if (this.DocFilter == 0) {
        return [
          { ActionName: "View", value: "View" },
          { ActionName: "Close", value: "Close" },
          { ActionName: "Approval", value: "Approval" },
        ];
      }
      return [
        { ActionName: "View", value: "View" },
        { ActionName: "Close", value: "Close" },
      ];
    }
  },
  methods: {
    getDocs(val) {
      const self = this;
      this.loading = true;
      self.docData = [];
      if (val) {
        // check permission
        if (self.getDocumentRights[val]) {
          self.docRights = self.getDocumentRights[val];
        } else {
          this.$store
              .dispatch("get", `/authorization/check-if-permitted/${val}`)
              .then((res) => {
                const newDocPermission = {...self.getDocumentRights}
                newDocPermission[val] = res;
                self.$store.commit("setDocumentRights", newDocPermission);
                self.docRights = res;
              })
              .catch((err) => {
                this.$refs.snackbar.show(err, "red");
              });
        }
        // end of permission check
        this.activeObject = val;
        if (!self.localSettings.enable){
          const url = `/documents/${val}?isDoc=${this.DocFilter}&docNum=${this.search}&page=${this.options.page}&per_page=${this.options.itemsPerPage}`;
          const data = {};
          data.created_by = this.created_by;
          this.$store
              .dispatch("post", {url, data})
              .then((res) => {
                if (res.ResultCode == 1200) {
                  self.docData = res.ResponseData.data;
                  self.options.page = res.ResponseData.current_page;
                  self.options.itemsPerPage = res.ResponseData.per_page;
                  self.totalItems = res.ResponseData.total;
                }
                self.loading = false;
              })
              .catch((err) => {
                this.$refs.snackbar.show(err, "red");
              });
        }
        if (self.localSettings.enable){
          self.getDocsLocal(val,self.DocFilter,self.search,self.options.page,self.options.itemsPerPage);
        }
        //get doc model
        if(self.getDocumentModel[val]){
          self.objectName = self.getDocumentModel[val].DocumentName;
        }
        else {
          this.$store
              .dispatch("get", `/doc_model/${val}`)
              .then((res) => {
                var newDocumentModel = { ...this.getDocumentModel };
                newDocumentModel[val] = res;
                this.$store.commit("setDocumentModel", newDocumentModel);
                self.objectName = res.DocumentName;
              })
              .catch((err) => {
                this.$refs.snackbar.show(err, "red");
              });
        }

        //document form setting
        if (!self.getDocumentFormSettings[val]){
          this.$store
              .dispatch("get", `/form_settings/${val}`)
              .then((res) => {
                this.$store.commit("loader", false);
                var newDocumentFormSetting = { ...this.getDocumentFormSetting };
                newDocumentFormSetting[val] = res;
                this.$store.commit("documentFormSetting/loadData", newDocumentFormSetting);
              })
              .catch((err) => {
                this.$store.commit("loader", false);
                console.log(err, "error");
              });

        }
        // end of doc title

        if (this.$route.query.withPayment == undefined) {
          this.withPayment = 0;
        }
        if (this.$route.query.withPayment == 1) {
          this.withPayment = 1;
        }
      }
    },
    getDocsLocal(ObjType,isDoc,docNum,page,perPage) {
      const self = this;
      self.loading = true;
      const url = self.localSettings.localurl + `/documents/${ObjType}?isDoc=${isDoc}&docNum=${docNum}&page=${page}&per_page=${perPage}`;
      axios.post(url, this.options).then((res) => {
        if (res.data.ResultCode == 1200) {
          self.docData = res.data.ResponseData.data;
          self.options.page = res.data.ResponseData.current_page;
          self.options.itemsPerPage = res.data.ResponseData.per_page;
          self.totalItems = res.data.ResponseData.total;
        }
        self.loading = false;
      });
    },
    openCloseDocumentDialog(item) {
      if (this.DocFilter != 1) {
        return;
      }
      this.dateItem = item;
      this.closeDocDialog = true;
    },

    openerrorDocDialog(item) {
      this.ErrorMessage = item.ErrorMessage;
      this.errorDocDialog = true;
    },
    closeDocument() {
      if (this.DocFilter != 1) {
        return;
      }
      const data = this.dateItem;
      const activeObject = data.ObjType;
      const url = "marketing-doc-close/" + activeObject + "/" + data.id;
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.closeDocDialog = false;
            self.$refs.snackbar.show(res.ResultDesc, "green");
            window.location.reload();
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err);
          //  console.log(err);
        });
    },
    processAction(item) {
      const self = this;
      const pos = this.docData.indexOf(item);
      if (pos === -1) {
        return;
      }
      this.docData[pos] = item;

      if (item.actionType == "Close" && item.DocStatus == "O") {
        this.openCloseDocumentDialog(item);
        return;
      }

      if (item.actionType == "Errors" && item.DocStatus == "E") {
        this.openerrorDocDialog(item);
        return;
      }

      if (item.actionType == "Approval" && item.DocStatus == "O") {
        this.loading = true;
        const url = `/marketing-doc-approvers/${this.activeObject}/${item.id}`;
        const data = {};
        data.created_by = this.created_by;
        this.$store
          .dispatch("post", { url, data })
          .then((res) => {
            if (res.ResultCode == 1200) {
              self.approversDetails = res.ResponseData.approvers;
              if (res.ResponseData) {
                this.approversDialog = true;
              }
            }
            self.loading = false;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
        return;
      }

      if (item.actionType == "View") {
        this.$router.push(
          `/sales/doc/${this.activeObject}/${item.id}?isDoc=${this.DocFilter}`
        );
      }
    },
    setFilter() {
      this.getDocs(this.activeObject);
    },
  },
  created() {
    // this.getDocs();
    // this.useLocalSearch();
  },

  formSettings(objectId) {
    this.$store.commit("loader", true);
    this.activeObject = objectId;
    const self = this;
    this.loading = true;
    if (self.getDocumentFormSettings[objectId]){
      this.$store.commit("loader", false);
      const documentFormSetting = self.getDocumentFormSettings[objectId];
      self.setup = documentFormSetting;
      self.tableRows = documentFormSetting.tabs[0].tableRows;
      self.seriesArray = documentFormSetting.Series;
      self.usersArray = documentFormSetting.users;
      self.branchesArray = documentFormSetting.branches;
      self.BPLId = documentFormSetting.BPLId;
      self.loading = false;
    }else{
      this.$store
          .dispatch("get", `/form_settings/${objectId}`)
          .then((res) => {
            this.$store.commit("loader", false);
            var newDocumentFormSetting = { ...this.getDocumentFormSetting };
            newDocumentFormSetting[objectId] = res;
            this.$store.commit("documentFormSetting/loadData", newDocumentFormSetting);
            self.setup = res;
            self.tableRows = res.tabs[0].tableRows;
            self.seriesArray = res.Series;
            self.usersArray = res.users;
            self.branchesArray = res.branches;
            self.BPLId = res.BPLId;
            self.loading = false;
          })
          .catch((err) => {
            this.$store.commit("loader", false);
            console.log(err, "error");
          });
    }


    if (this.$route.query.isDoc == 1 || this.$route.query.isDoc == 2) {
      if (self.docStatus !== "C") {
        if (objectId == 13) {
          // set object Name
          this.copyDocs = [{ name: "A/R Credit Memo", id: 14 }];
        }

        if (objectId == 13) {
          this.copyDocs = [{ name: "A/R Credit Memo", id: 14 }];
        }

        if (objectId == 15) {
          this.copyDocs = [];
          this.copyDocs = [
            { name: "Return", id: 16 },
            { name: "A/R Invoice", id: 13 },
          ];
        }

        if (objectId == 17) {
          this.copyDocs = [
            { name: "A/R Invoice", id: 13 },
            { name: "Delivery", id: 15 },
          ];
        }
      }
    }
    // end
    if (this.activeObject !== 13) {
      this.withPayment = "N";
    }
    if (this.activeObject == 13) {
      this.withPayment = "Y";
    }

    // set object name
    if(self.getDocumentModel[objectId]){
      self.objectName = self.getDocumentModel[objectId].DocumentName;
    }
    else {
      this.$store
          .dispatch("get", `/doc_model/${objectId}`)
          .then((res) => {
            var newDocumentModel = { ...this.getDocumentModel };
            newDocumentModel[objectId] = res;
            this.$store.commit("setDocumentModel", newDocumentModel);
            self.objectName = res.DocumentName;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
    }
  },

};
</script>